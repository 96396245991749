import React, { useState, forwardRef, useEffect } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
// @ts-ignore
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormPage } from "../../components/Form/FormPage";
import { Header } from "./Header";
import { FormContainer } from "../../components/Form/FormContainer";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormField } from "../../components/Form/FormField";
import { SubmitButton } from "../../components/Form/SubmitButton";
import { FileInputField } from "../../components/Form/FileInputField";

const schema = z.object({
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  phone: z.string().optional(),
  email: z.string().email({ message: "Please enter a valid email address" }),
  street: z.string().min(1, { message: "Street address is required" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(1, { message: "State is required" }),
  zip: z.string().min(1, { message: "Zip code is required" }),
  specifications: z.string().min(1, { message: "Specifications are required" }),
  preferredEndDate: z.string().optional(),
  selectedFiles: z.array(z.string()).optional(),
});

type QuoteFormFields = z.infer<typeof schema>;

const QuoteForm = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
  } = useForm<QuoteFormFields>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<QuoteFormFields> = async (data) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      street,
      city,
      state,
      zip,
      specifications,
      preferredEndDate,
    } = data;

    let quoteRequestObject = {
      contactPerson: {
        name: `${firstName} ${lastName}`,
        email,
        phone: phone || undefined,
      },
      address: {
        street,
        city,
        state,
        zip,
      },
      notes: specifications,
      images: selectedFiles || undefined,
      preferredEndDate: preferredEndDate || undefined,
    };

    try {
      const quoteResponse = await sendFormDataToServer(quoteRequestObject);

      const quoteRequestId = quoteResponse?.data?.quoteRequest?._id;

      if (quoteRequestId) {
        const newEmail = {
          message: ``,
          userEmail: email,
          subject: `Thank You for Your Quote Request - Here's How to Track It!`,
          html: `<p>Thank you for submitting a quote request. Your quote request number is ${quoteRequestId}. To check the status of your request, please visit the following link:<br></p><a href=https://bertonihandyman.com/quote-request-status/${quoteRequestId}>Quote Request Status</a>`,
        };
        const emailResponse = await axios.post(`${API_URL}/email`, newEmail);
        const newAdminEmail = {
          message: `You have received a new quote request, please check the dashboard`,
          userEmail: "bertoni.sean@bertonihandyman.com",
        };
        const adminEmailResponse = await axios.post(
          `${API_URL}/email`,
          newAdminEmail
        );
      }

      reset();
      navigate(`/get-a-quote/success`);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 429) {
        toast.error(
          "Too many attempts. Please wait ten minutes and try again."
        );
      } else {
        toast.error(
          "Error submitting. Please check your inputs and try again."
        );
      }
    }
  };

  const sendFormDataToServer = (formData: any) => {
    return axios.post(`${API_URL}/quoterequest/create`, formData);
  };

  return (
    <FormPage>
      <Header />

      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormField
          id="firstName"
          label="First Name*"
          placeholder="Jane"
          register={register}
          errors={errors.firstName}
          type="input"
        />
        <FormField
          id="lastName"
          label="Last Name*"
          placeholder="Doe"
          register={register}
          errors={errors.lastName}
          type="input"
        />
        <FormField
          id="phone"
          label="Phone"
          placeholder="(123) 456-7890"
          register={register}
          errors={errors.phone}
          type="input"
        />
        <FormField
          id="email"
          label="Email*"
          placeholder="jane.doe@example.com"
          register={register}
          errors={errors.email}
          type="input"
        />
        <FormField
          id="street"
          label="Street*"
          placeholder="123 Main St"
          register={register}
          errors={errors.street}
          type="input"
        />
        <FormField
          id="city"
          label="City*"
          placeholder="Anytown"
          register={register}
          errors={errors.city}
          type="input"
        />
        <FormField
          id="state"
          label="State*"
          placeholder="CA"
          register={register}
          errors={errors.state}
          type="input"
        />
        <FormField
          id="zip"
          label="Zip*"
          placeholder="12345"
          register={register}
          errors={errors.zip}
          type="input"
        />
        <div className="md:col-span-2">
          <FormField
            id="specifications"
            label="Specifications*"
            placeholder="General/Technical Specifications of Service and Requirements"
            register={register}
            errors={errors.specifications}
            type="textarea"
          />
        </div>

        <FileInputField
          setSelectedFiles={setSelectedFiles}
          setValue={setValue}
          errors={errors.selectedFiles}
        />

        {/* Preferred End Date Input */}
        <div className="flex flex-col md:col-span-2 md:w-1/2">
          <label htmlFor="preferredEndDate">Preferred End Date</label>
          <input
            className="shadow-sm rounded-md bg-gray-50 border border-gray-300 text-gray-900 p-2.5"
            type="date"
            {...register("preferredEndDate")}
          />
          {errors.preferredEndDate && <p>{errors.preferredEndDate.message}</p>}
        </div>

        <div>
          <SubmitButton
            isSubmitting={isSubmitting}
            buttonText="Send"
            submittingText="Sending"
          />
        </div>
      </FormContainer>
    </FormPage>
  );
};

export default QuoteForm;
