import React from "react";
import { ModalLayout } from "./ModalLayout";

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <ModalLayout onOutsideClick={onClose}>
      <h2 className="text-lg font-bold">Confirm Deletion</h2>
      <p className="text-sm max-w-xs">
        Are you sure you want to delete this item? This action cannot be undone.
      </p>
      <div className="flex justify-end mt-4 space-x-2">
        <button
          className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Delete
        </button>
      </div>
    </ModalLayout>
  );
};

export default DeleteModal;
