import React from "react";

export const Header = () => {
  return (
    <div className="flex flex-col items-center pb-2">
      <h1 className="text-4xl font-extrabold text-center text-gray-900 py-4">
        Get a Quote
      </h1>
      <span>
        Already have a Quote?{" "}
        <a
          href="/quote-request-status"
          className="underline text-blue-500 hover:text-blue-400"
        >
          Check Status
        </a>
      </span>
    </div>
  );
};
