import React from "react";
import Skeleton from "react-loading-skeleton";

type ActivitySectionProps = {
  isLoading: boolean;
  newClientsThisWeek: number;
  newClientsThisMonth: number;
};

export const ActivitySection: React.FC<ActivitySectionProps> = ({
  isLoading,
  newClientsThisMonth,
  newClientsThisWeek,
}) => {
  return (
    <div>
      <h2 className="text-xl font-semibold text-gray-700">Client Activity</h2>
      <div className="flex justify-between">
        <div>
          {isLoading ? (
            <Skeleton height={20} width={50} />
          ) : (
            <p className="text-2xl font-medium">{newClientsThisWeek}</p>
          )}
          <p className="text-sm text-gray-600">
            New quote requests (this week)
          </p>
        </div>
        <div>
          {isLoading ? (
            <Skeleton height={20} width={50} />
          ) : (
            <p className="text-2xl font-medium">{newClientsThisMonth}</p>
          )}
          <p className="text-sm text-gray-600">
            New quote requests (this month)
          </p>
        </div>
      </div>
    </div>
  );
};
