import React from "react";
import { TableHead } from "../../../components/Table/TableHead";
import { TableHeader } from "../../../components/Table/TableHeader";
import { QuoteRequest } from "../../../Types/types";
import Skeleton from "react-loading-skeleton";
import { TableRow } from "../../../components/Table/TableRow";
import { TableData } from "../../../components/Table/TableData";
import { Link } from "react-router-dom";

type DashboardTableProps = {
  quoteRequests: QuoteRequest[];
  isLoading: boolean;
};

export const DashboardTable: React.FC<DashboardTableProps> = ({
  quoteRequests,
  isLoading,
}) => {
  return (
    <table className="min-w-full">
      <TableHead>
        <tr>
          <TableHeader title={"Client Name"} />
          <TableHeader title={"Email"} />
          <TableHeader title={"Phone"} />
          <TableHeader title={"Status"} />
          <TableHeader title={"Action"} />
        </tr>
      </TableHead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={1000}>
              <Skeleton count={10} height={25} />
            </td>
          </tr>
        ) : (
          <>
            {quoteRequests.map((quoteRequest, index) => {
              return (
                <TableRow
                  key={`${quoteRequest?._id}-${index}`}
                  className="hover:bg-yellow-50 transition ease-in-out"
                >
                  <TableData>{quoteRequest?.contactPerson?.name}</TableData>
                  <TableData>{quoteRequest?.contactPerson?.email}</TableData>
                  <TableData>{quoteRequest?.contactPerson?.phone}</TableData>
                  <TableData>{quoteRequest?.quoteStatus}</TableData>
                  <TableData>
                    <Link
                      to={`/admin/quote-requests/${quoteRequest?._id}`}
                      className="text-black underline hover:text-blue-700"
                    >
                      View
                    </Link>
                  </TableData>
                </TableRow>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};
