import React from "react";
import { IoIosAddCircle } from "react-icons/io";
import { BlueBeatLoader } from "./Loaders/BeatLoader";

type AddButtonProps = {
  buttonText: string;
  handleClick: () => void;
  isLoading?: boolean;
};

export const AddButton: React.FC<AddButtonProps> = ({
  buttonText,
  handleClick,
  isLoading = false,
}) => {
  if (isLoading) {
    return <BlueBeatLoader size={7} />;
  } else
    return (
      <button
        onClick={handleClick}
        className="w-fit flex items-center gap-1 bg-green-500 hover:bg-green-400 transition duration-150 ease-in-out rounded-md shadow-lg text-white p-2 focus:ring-green-700 focus:ring-1"
      >
        {buttonText}
        <IoIosAddCircle />
      </button>
    );
};
