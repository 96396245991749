import React from "react";
import { BeatLoader } from "react-spinners";

type SubmitButtonProps = {
  isSubmitting: boolean;
  buttonText: string;
  submittingText: string;
};

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  isSubmitting,
  buttonText,
  submittingText,
}) => {
  return (
    <button
      type="submit"
      className={`p-2 px-10 my-4 font-bold text-white transition-transform transform bg-orange-500 rounded-md hover:scale-105 ${
        isSubmitting ? "opacity-50" : ""
      }`}
      disabled={isSubmitting}
    >
      {isSubmitting ? (
        <div className="flex items-center gap-3 text-orange-900">
          {submittingText} <BeatLoader size={7} color="#7c2d12" />{" "}
        </div>
      ) : (
        <span>{buttonText}</span>
      )}
    </button>
  );
};
