import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeatLoader, SyncLoader } from "react-spinners";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../components/Form/Input";
import { TextArea } from "../components/Form/TextArea";
import { SubmitButton } from "../components/Form/SubmitButton";
import { Label } from "../components/Form/Label";
import { FormField } from "../components/Form/FormField";
import { FormContainer } from "../components/Form/FormContainer";
import { FormPage } from "../components/Form/FormPage";

const schema = z.object({
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  phone: z.string().optional(),
  email: z.string().email({ message: "Please enter a valid email address" }),
  message: z.string().min(1, { message: "Message is required" }),
});

type ContactFormFields = z.infer<typeof schema>;

const Contact = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ContactFormFields>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<ContactFormFields> = async (data) => {
    const newEmail = {
      message: ``,
      userEmail: "Bertoni.Sean@bertonihandyman.com",
      subject: `New Contact Request For Bertoni Handyman Services`,
      html: `<p>Client Name: ${data.firstName} ${
        data.lastName
      }</p><br><p>Client Email: ${data.email}</p><br><p>Phone: ${
        data.phone || "Not provided"
      }</p><br><p>${data.message}</p><br>`,
    };

    try {
      await axios.post(`${API_URL}/email`, newEmail);
      reset();
      toast.success("Successfully submitted contact request!");
    } catch (error) {
      toast.error("Error submitting contact request. Please try again.");
      console.error("Error sending email:", error);
    }
  };

  return (
    <FormPage>
      <h2 className="pt-10 text-4xl font-extrabold text-center text-gray-900 py-9">
        Contact
      </h2>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormField
          id="firstName"
          label="First Name*"
          placeholder="Jane"
          register={register}
          errors={errors.firstName}
          type="input"
        />
        <FormField
          id="lastName"
          label="Last Name*"
          placeholder="Doe"
          register={register}
          errors={errors.firstName}
          type="input"
        />
        <FormField
          id="phone"
          label="Phone"
          placeholder="(123) 456-7890"
          register={register}
          errors={errors.phone}
          type="input"
        />
        <FormField
          id="email"
          label="Email*"
          placeholder="jane.doe@example.com"
          register={register}
          errors={errors.email}
          type="input"
        />
        <div className="md:col-span-2">
          <FormField
            id="message"
            label="Message*"
            placeholder="Describe your home repair needs or ask a question..."
            register={register}
            errors={errors.message}
            type="textarea"
          />
        </div>
        <div>
          <SubmitButton
            isSubmitting={isSubmitting}
            buttonText="Send"
            submittingText="Sending"
          />
        </div>
      </FormContainer>
    </FormPage>
  );
};

export default Contact;
