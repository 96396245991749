import React, { useEffect, useRef, useState } from "react";
import { ModalLayout } from "./ModalLayout";
import { BlueBeatLoader } from "./Loaders/BeatLoader";
import Select from "react-select";

type FormField<T> = {
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  options?: Array<{ value: string | number; label: string }>;
  component?: React.ComponentType<any>;
};

type FormModalProps<T> = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: T) => void;
  initialFormData: T;
  formFields: FormField<T>[];
  title: string;
  isLoading?: boolean;
  buttonText?: string;
  closeOnSubmit?: boolean;
};

export const FormModal = <T,>({
  isOpen,
  onClose,
  onSubmit,
  initialFormData,
  formFields,
  title,
  isLoading = false,
  buttonText = "Add",
  closeOnSubmit = true,
}: FormModalProps<T>) => {
  const [formData, setFormData] = useState<T>(initialFormData);

  useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData(initialFormData);
    if (closeOnSubmit) onClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name: string, value: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.map((option: any) => option.value),
    }));
  };

  return !isOpen ? null : (
    <ModalLayout onOutsideClick={onClose}>
      <h2 className="text-3xl font-bold pb-4">{title}</h2>
      <form onSubmit={handleFormSubmit}>
        <div
          className={`grid ${
            formFields?.length > 3 ? "grid-cols-2" : ""
          } gap-4 pb-4`}
        >
          {formFields.map((field) => (
            <React.Fragment key={field.name}>
              {field.type === "select" && field?.options ? (
                <div className="flex flex-col">
                  <label>{field.label}</label>

                  <select
                    name={field.name}
                    onChange={handleInputChange as any}
                    defaultValue={
                      (formData[field.name as keyof T] as string) || ""
                    }
                    required
                    className="border border-gray-800 rounded-md p-2 shadow-lg"
                  >
                    <option value="" disabled>
                      Select {field.label}
                    </option>
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : field.type === "react-select" ? (
                <div className="flex flex-col">
                  <label>{field.label}</label>

                  <Select
                    isMulti
                    options={field.options}
                    value={field.options?.filter((option) =>
                      (formData[field.name as keyof T] as string[])?.includes(
                        option.value as string
                      )
                    )}
                    onChange={(selected) =>
                      handleSelectChange(field.name, selected)
                    }
                    placeholder={field.placeholder || "Select options"}
                    isClearable={false}
                    className=""
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        padding: "0.5rem", // Matches `p-2`
                        borderColor: state.isFocused ? "#1f2937" : "#1f2937", // Matches `border-gray-800`
                        boxShadow:
                          "0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)", // Matches `shadow-lg`
                        borderRadius: "0.375rem", // Matches `rounded-md`
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#6c757d",
                      }),
                      multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: "rgba(0, 123, 255, 0.1)",
                      }),
                      multiValueLabel: (provided) => ({
                        ...provided,
                        color: "#007bff",
                      }),
                      multiValueRemove: (provided) => ({
                        ...provided,
                        ":hover": {
                          backgroundColor: "rgba(0, 123, 255, 0.1)",
                          color: "#007bff",
                        },
                      }),
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-col">
                  <label>{field.label}</label>

                  <input
                    type={field.type}
                    name={field.name}
                    onChange={handleInputChange}
                    defaultValue={
                      (formData[field.name as keyof T] as string) || ""
                    }
                    required
                    className="border border-gray-800 rounded-md p-2 shadow-lg"
                    placeholder={field?.placeholder || ""}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex justify-end items-center gap-2">
          <button
            type="button"
            onClick={onClose}
            className=" bg-gray-300 hover:bg-gray-400 py-2 px-4 rounded transition duration-150 ease-in-out"
          >
            Cancel
          </button>
          {isLoading ? (
            <BlueBeatLoader size={7} />
          ) : (
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded transition duration-150 ease-in-out"
            >
              {buttonText}
            </button>
          )}
        </div>
      </form>
    </ModalLayout>
  );
};
