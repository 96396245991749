import React, { useState } from "react";
import { ModalLayout } from "../../../components/ModalLayout";
import { useJobContext } from "../../../providers/JobProvider";
import SearchBar from "../../../components/SearchBar";
import { useSearchEmployees } from "../../../hooks/data/users/useSearchEmployees";
import { EmployeeDropdown } from "./EmployeeDropdown";
import { SearchTags } from "./SearchTags";
import { User } from "../../../Types/types";
import { MdDelete } from "react-icons/md";
import { AddButton } from "../../../components/AddButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAssignWorkerMutation } from "../../../hooks/data/jobs/useAssignWorkersMutation";

type AssignWorkersModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AssignWorkersModal: React.FC<AssignWorkersModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { job } = useJobContext();
  const initialWorkers = job?.assignedTo || [];

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [assignedWorkers, setAssignedWorkers] =
    useState<User[]>(initialWorkers);
  const [isLoading, setIsLoading] = useState(false);

  const { data: employees, isLoading: employeesIsLoading } = useSearchEmployees(
    searchTerm && searchTerm.length > 0 ? searchTerm : null
  );

  const assignWorkersMutation = useAssignWorkerMutation(job?._id || "", () => {
    setIsLoading(false);
    onClose();
  });

  const handleEmployeeSelect = (employee: User) => {
    if (assignedWorkers.find((worker) => worker._id === employee._id)) {
      toast.error("Worker already assigned");
      return;
    }
    setAssignedWorkers([...assignedWorkers, employee]);
    setSearchTerm("");
  };

  const handleRemoveEmployee = (employee: User) => {
    setAssignedWorkers(
      assignedWorkers.filter((worker) => worker._id !== employee._id)
    );
  };

  const handleConfirm = () => {
    setIsLoading(true);
    assignWorkersMutation.mutate(assignedWorkers);
  };

  if (isOpen) {
    return (
      <ModalLayout onOutsideClick={() => onClose()}>
        <div className="">
          <h1 className="text-xl font-bold pb-2">Assign New Worker</h1>
          <div className="flex pb-6">
            <div className="relative">
              <SearchBar
                searchTerm={searchTerm}
                onSearchChange={setSearchTerm}
                debounceTime={700}
                placeholder="Search workers..."
              />
              {searchTerm && (
                <EmployeeDropdown
                  employees={employees}
                  onEmployeeSelect={(employee) => {
                    handleEmployeeSelect(employee);
                  }}
                  isLoading={employeesIsLoading}
                />
              )}
            </div>
            <SearchTags
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
          </div>

          <div className="flex flex-col gap-1 pb-6">
            <h1 className="text-xl font-bold pb-2">Assigned Workers</h1>
            {assignedWorkers?.length ? (
              assignedWorkers?.map((user) => (
                <div className="flex flex-row gap-2 items-center">
                  <span key={user._id}>{user.fullName}</span>
                  <MdDelete
                    className="text-red-500 hover:cursor-pointer hover:text-red-400"
                    title="Remove worker"
                    onClick={() => {
                      handleRemoveEmployee(user);
                    }}
                  />
                </div>
              ))
            ) : (
              <span>No workers assigned</span>
            )}
          </div>
          <div className="flex space-x-2 justify-end">
            <button
              className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
              onClick={onClose}
            >
              Cancel
            </button>
            <AddButton
              buttonText="Confirm"
              handleClick={handleConfirm}
              isLoading={isLoading}
            />
          </div>
        </div>
      </ModalLayout>
    );
  } else return null;
};
