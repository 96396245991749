import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { FormModal } from "../../../components/FormModal";
import type { User } from "../../../Types/types";
import { useAddUser } from "../../../hooks/data/users/useAddUser";
import { useEditUser } from "../../../hooks/data/users/useEditUser";

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode?: "add" | "edit";
  initialFormData?: User;
}

enum UserRole {
  Employee = "employee",
  Admin = "admin",
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  isOpen,
  onClose,
  mode = "add",
  initialFormData = {
    _id: "",
    username: "",
    password: "",
    fullName: "",
    email: "",
    phone: "",
    role: UserRole.Employee,
    tags: [],
  },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const addUserMutation = useAddUser();
  const editUserMutation = useEditUser();

  const formFields = [
    {
      name: "username",
      label: "Username",
      type: "text",
      placeholder: "Username",
    },
    ...(mode === "add"
      ? [
          {
            name: "password",
            label: "Password",
            type: "password",
            placeholder: "Password",
          },
        ]
      : []),
    {
      name: "fullName",
      label: "Full Name",
      type: "text",
      placeholder: "Full Name",
    },
    { name: "email", label: "Email", type: "email", placeholder: "Email" },
    { name: "phone", label: "Phone Number", type: "tel", placeholder: "Phone" },
    {
      name: "role",
      label: "Role",
      type: "select",
      options: [
        { value: UserRole.Employee, label: UserRole.Employee },
        { value: UserRole.Admin, label: UserRole.Admin },
      ],
    },
    {
      name: "tags",
      label: "Tags",
      type: "react-select",
      options: [
        { value: "tag1", label: "Tag 1" },
        { value: "tag2", label: "Tag 2" },
      ],
    },
  ];

  const handleEdit = async (formData: User) => {
    setIsLoading(true);

    if (mode === "edit") {
      const updatedUser: User = {
        _id: initialFormData._id,
        username: formData.username,
        password: formData.password,
        fullName: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        role: formData.role,
        tags: formData.tags,
      };

      await editUserMutation.mutateAsync(updatedUser);
    } else {
      const newUser: User = {
        username: formData.username,
        password: formData.password,
        fullName: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        role: formData.role,
        tags: formData.tags,
      };

      await addUserMutation.mutateAsync(newUser);
    }

    setIsLoading(false);
  };

  if (!isOpen) return null;

  if (mode === "edit") {
    return (
      <FormModal<User>
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleEdit}
        initialFormData={initialFormData}
        formFields={formFields}
        title="Edit User"
        isLoading={isLoading}
        buttonText="Update"
        closeOnSubmit={false}
      />
    );
  } else
    return (
      <FormModal<User>
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleEdit}
        initialFormData={initialFormData}
        formFields={formFields}
        title="Add User"
        isLoading={isLoading}
        closeOnSubmit={false}
      />
    );
};

export default EditUserModal;
