import React from "react";
import { Label } from "./Label";
import { Input } from "./Input";
import { TextArea } from "./TextArea";

type FormFieldsProps = {
  id: string;
  label: string;
  placeholder: string;
  register: any;
  errors: any;
  type: "input" | "textarea";
  rows?: number;
};

export const FormField: React.FC<FormFieldsProps> = ({
  id,
  label,
  placeholder,
  register,
  errors,
  type,
  rows = 6,
}) => {
  return (
    <div>
      <Label htmlFor={id} text={label} />
      {type === "input" && (
        <Input id={id} register={register} placeholder={placeholder} />
      )}
      {type === "textarea" && (
        <TextArea
          id={id}
          rows={rows}
          register={register}
          placeholder={placeholder}
        />
      )}
      {errors && <p className="text-red-500">{errors.message}</p>}
    </div>
  );
};
