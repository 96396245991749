import { useState, useMemo } from "react";
import Pagination from "../../../components/Pagination/Pagination";
import EditUserModal from "./EditUserModal";
import type { User } from "../../../Types/types";
import { TabLayout } from "../../../components/TabLayout";
import SearchBar from "../../../components/SearchBar";
import { UsersTable } from "./UsersTable";
import { ResultsCount } from "../../../components/ResultsCount";
import { useUsers } from "../../../hooks/useUsers";
import { AddButton } from "../../../components/AddButton";
import { set } from "lodash";
import { useDeleteUser } from "../../../hooks/data/users/useDeleteUser";
import DeleteModal from "../../../components/DeleteModal";

const USERS_PER_PAGE = 10;

export const Users = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [initialFormData, setInitialFormData] = useState<User>({
    _id: "",
    username: "",
    password: "",
    fullName: "",
    email: "",
    phone: "",
    role: "employee",
  });
  const [editMode, setEditMode] = useState<"add" | "edit">("add");

  const { data, isError, error, isLoading } = useUsers(
    currentPage,
    USERS_PER_PAGE
  );

  const deleteUserMutation = useDeleteUser(currentPage, USERS_PER_PAGE);

  const totalPages = data?.totalPages || 0;

  const filteredUsers: User[] = useMemo(() => {
    const users = data?.users || [];

    if (!users || users.length === 0) return [];
    if (!searchTerm) return users;
    return users.filter(
      (user) =>
        user?.username?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        user?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        user?.phone?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        user?.fullName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }, [data?.users, searchTerm]);

  if (isError) {
    console.error("Error fetching users:", error);
    return <div>Error fetching users. Please try again later.</div>;
  }

  const handleEditUser = (user: User) => {
    setInitialFormData(user);
    setEditMode("edit");
    setIsEditModalOpen(true);
  };

  const handleAddUser = () => {
    setEditMode("add");
    setIsEditModalOpen(true);
  };

  const handleDeleteUser = (userId: string) => {
    setUserToDelete(userId);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setInitialFormData({
      _id: "",
      username: "",
      password: "",
      fullName: "",
      email: "",
      phone: "",
      role: "employee",
    });
  };

  return (
    <TabLayout title="Users">
      <div className="flex items-center mb-4 gap-6">
        <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />

        <AddButton handleClick={handleAddUser} buttonText="Add User" />
      </div>

      <ResultsCount results={filteredUsers?.length} isLoading={isLoading} />

      <UsersTable
        users={filteredUsers}
        isLoading={isLoading}
        handleEditUser={handleEditUser}
        handleDeleteUser={handleDeleteUser}
      />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
        isLoading={isLoading}
      />

      <EditUserModal
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        initialFormData={initialFormData}
        mode={editMode}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => deleteUserMutation.mutate(userToDelete)}
      />
    </TabLayout>
  );
};
