import { useQuery, UseQueryResult } from "@tanstack/react-query";
import authorizedRequest from "../../../utils/authorizedRequest";
import type { QuoteRequest } from "../../../Types/types";

type useFetchByStatusResult = {
  data: QuoteRequest[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  refetch: () => void;
};

// Fetches quote requests
export const useFetchByStatus = (
  status: string,
  limit?: number
): useFetchByStatusResult => {
  const API_URL = process.env.REACT_APP_API_URL as string;

  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  }: UseQueryResult<QuoteRequest[]> = useQuery<QuoteRequest[]>({
    queryKey: ["quote-requests", status],
    queryFn: async (): Promise<QuoteRequest[]> => {
      const response = await authorizedRequest.get(
        `${API_URL}/quoterequest/byStatus?status=Pending&limit=${limit || 0}`
      );
      return response?.data as QuoteRequest[];
    },
    refetchInterval: 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
};
