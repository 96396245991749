import "./App.css";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import GetAQuote from "./pages/GetAQuote/GetAQuote";
import Login from "./pages/LoginPage/Login";
import ContactPage from "./pages/ContactPage";
import ThankYou from "./pages/ThankYou";
import AdminPage from "./pages/AdminPage/AdminPage";
import NavbarWrapper from "./components/NavbarWrapper";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import ScrollToTop from "./utils/ScrollToTop";
import ProtectedRoute from "./components/ProtectedRoute";
import Unauthorized from "./pages/Unauthorized";
import QuoteAccepted from "./pages/AcceptedQuote/QuoteAccepted";
import QuoteDeclined from "./pages/DeclinedQuote/QuoteDeclined";
import QuoteStatusInput from "./pages/QuoteRequestStatus/QuoteStatusInput";
import QuoteRequestStatus from "./pages/QuoteRequestStatus/QuoteRequestStatus";
import { EmployeePage } from "./pages/EmployeePage/EmployeePage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { QuoteRequestSuccess } from "./pages/GetAQuote/QuoteRequestSuccess";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer position="top-center" />
      <Router>
        <ScrollToTop />
        <div className="flex flex-col min-h-screen">
          <NavbarWrapper />
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/get-a-quote" element={<GetAQuote />} />
              <Route
                path="/get-a-quote/success"
                element={<QuoteRequestSuccess />}
              />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/quote-request-status"
                element={<QuoteStatusInput />}
              />
              <Route
                path="/quote-request-status/:quoteRequestId"
                element={<QuoteRequestStatus />}
              />
              {/* <Route path="/schedule" element={<ScheduleAppointment />} /> */}
              {/* <Route path="/chat" element={<Chat />} /> */}
              <Route path="/quote/accepted" element={<QuoteAccepted />} />
              <Route path="/quote/declined" element={<QuoteDeclined />} />
              {/* <Route path="/create-account" element={<CreateAccount />} /> */}
              {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
              {/* <Route path="/Reset-Password" element={<ResetPassword />} /> */}
              {/* <Route path="/feedback" element={<FeedbackPage />} /> */}
              {/* <Route
                path="/create-account-success"
                element={<CreateAccountSuccess />}
              /> */}
              <Route
                path="/admin/*"
                element={
                  <ProtectedRoute permittedRoles={["admin"]}>
                    <AdminPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/employee/*"
                element={
                  <ProtectedRoute permittedRoles={["admin"]}>
                    <EmployeePage />
                  </ProtectedRoute>
                }
              />

              {/* <Route path="/quote-requests" element={<QuoteRequests />} /> */}
              {/* <Route path="/add-testimonial" element={<AddTestimonial />} /> */}
              <Route path="*" element={<PageNotFound />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
