import { useMutation, useQueryClient } from "@tanstack/react-query";
import authorizedRequest from "../../../utils/authorizedRequest";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { User } from "../../../Types/types";

const API_URL = process.env.REACT_APP_API_URL;

export const useDeleteUser = (page: number, limit: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userId: string) => {
      return await authorizedRequest.delete(`${API_URL}/users/${userId}`);
    },
    onMutate: async (userId: string) => {
      await queryClient.cancelQueries({ queryKey: ["users", page, limit] });

      const previousUsersData = queryClient.getQueryData<{
        users: User[];
        totalPages: number;
      }>(["users", page, limit]);

      if (previousUsersData) {
        queryClient.setQueryData<{ users: User[]; totalPages: number }>(
          ["users", page, limit],
          {
            ...previousUsersData,
            users: previousUsersData.users.filter(
              (user) => user._id !== userId
            ),
          }
        );
      }

      return { previousUsersData };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["users", page, limit] });
    },
    onError: (context: {
      previousUsersData?: { users: User[]; totalPages: number };
    }) => {
      queryClient.setQueryData(
        ["users", page, limit],
        context.previousUsersData
      );
      toast.error("Error deleting user. Please try again");
    },
  });
};
