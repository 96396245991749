import React from "react";

type FormContainerProps = {
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

export const FormContainer: React.FC<FormContainerProps> = ({
  children,
  onSubmit,
}) => {
  return (
    <form
      className="grid gap-4 grid-cols-1 md:grid-cols-2 p-6 bg-gray-200 rounded-md"
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};
