import React from "react";

type LabelProps = {
  htmlFor: string;
  text: string;
};

export const Label: React.FC<LabelProps> = ({ htmlFor, text }) => {
  return (
    <label
      htmlFor={htmlFor}
      className="block my-2 text-sm font-medium text-left text-gray-900"
    >
      {text}
    </label>
  );
};
