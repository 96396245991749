import { useMutation, useQueryClient } from "@tanstack/react-query";
import authorizedRequest from "../../../utils/authorizedRequest";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { User } from "../../../Types/types";

export const useAssignWorkerMutation = (
  jobId: string,
  onSuccessCallback?: () => void
) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const queryClient = useQueryClient();

  const assignWorkerMutation = useMutation({
    mutationFn: async (workers: User[]) => {
      return await authorizedRequest.post(`${API_URL}/jobs/${jobId}/assign`, {
        workers,
      });
    },
    onSuccess: () => {
      toast.success(`User added successfully`);
      queryClient.invalidateQueries({ queryKey: ["job", jobId] });
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      toast.error("Error adding user. Please try again");
      console.error("Error adding user:", error);
    },
  });

  return assignWorkerMutation;
};
