import React from "react";
import { UseFormRegister } from "react-hook-form";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  register: UseFormRegister<any>;
  id: string;
  placeholder?: string;
}
export const TextArea: React.FC<TextAreaProps> = ({
  register,
  id,
  placeholder,
  ...props
}) => {
  return (
    <textarea
      id={id}
      {...register(id)}
      className="shadow-sm rounded-md bg-gray-50 border border-gray-300 text-gray-900 w-full p-2.5"
      placeholder={placeholder}
      {...props}
    />
  );
};
