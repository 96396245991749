import React from "react";
import Skeleton from "react-loading-skeleton";

type HeaderCardProps = {
  icon: React.ComponentType<{ className: string }>;
  title: string;
  value: string | number;
  onClick?: () => void;
  isLoading?: boolean;
};

export const HeaderCard: React.FC<HeaderCardProps> = ({
  icon: Icon,
  title,
  value,
  onClick,
  isLoading = false,
}) => {
  return (
    <div
      className={`p-4 bg-neutral-200 rounded-lg shadow-lg flex-1 ${
        onClick
          ? "cursor-pointer hover:bg-neutral-300 transition ease-in-out"
          : ""
      }`}
      onClick={isLoading ? () => {} : onClick}
    >
      {isLoading ? (
        <>
          <Skeleton
            height={25}
            width={25}
            circle={true}
            baseColor="#cbd5e1"
            highlightColor="#e5e7eb"
          />
          <Skeleton
            height={20}
            width={100}
            baseColor="#cbd5e1"
            highlightColor="#e5e7eb"
          />
          <Skeleton
            height={20}
            width={60}
            baseColor="#cbd5e1"
            highlightColor="#e5e7eb"
          />
        </>
      ) : (
        <>
          <Icon className="text-xl text-black-500" />
          <p className="text-sm text-gray-600 mt-2 font-bold">{title}</p>
          <p className="text-2xl font-medium">{value}</p>{" "}
        </>
      )}
    </div>
  );
};
