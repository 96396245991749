import React, { useEffect, useMemo, useState } from "react";
import { IoBriefcaseOutline } from "react-icons/io5";
import { LuClock } from "react-icons/lu";
import { CiBadgeDollar } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import type { QuoteRequest } from "../../../Types/types";
import { SyncLoader } from "react-spinners";
import authorizedRequest from "../../../utils/authorizedRequest";
import { useFetchQuoteRequests } from "../../../hooks/data/quoteRequests/useFetchQuoteRequests";
import { useFetchByStatus } from "../../../hooks/data/quoteRequests/useFetchByStatus";
import { HeaderCard } from "./HeaderCard";
import { TabLayout } from "../../../components/TabLayout";
import { DashboardTable } from "./DashboardTable";
import Skeleton from "react-loading-skeleton";
import { ActivitySection } from "./ActivitySection";

const AdminDashboard = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const navigateToQuoteRequests = (filterStatus: string) => {
    navigate("/admin/quote-requests", { state: filterStatus });
  };

  const {
    data: pendingQuoteRequests,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetchByStatus("Pending");

  const newClientsThisWeek = useMemo(() => {
    return pendingQuoteRequests?.filter((quoteRequest) => {
      const quoteRequestDate = new Date(quoteRequest.quoteRequestDate);
      const now = new Date();
      const oneWeekAgo = new Date(new Date().setDate(now.getDate() - 7));
      return quoteRequestDate >= oneWeekAgo;
    }).length;
  }, [pendingQuoteRequests]);

  const newClientsThisMonth = useMemo(() => {
    return pendingQuoteRequests?.filter((quoteRequest) => {
      const quoteRequestDate = new Date(quoteRequest.quoteRequestDate);
      const now = new Date();
      const oneMonthAgo = new Date(new Date().setMonth(now.getMonth() - 1));
      return quoteRequestDate >= oneMonthAgo;
    }).length;
  }, [pendingQuoteRequests]);

  return (
    <TabLayout title="Dashboard">
      <div className="flex gap-4 p-6">
        <HeaderCard
          icon={IoBriefcaseOutline}
          title="Total Completed Jobs"
          value={0}
          onClick={() => navigateToQuoteRequests("Completed")}
          isLoading={isLoading}
        />

        <HeaderCard
          icon={LuClock}
          title="Pending Quote Requests"
          value={pendingQuoteRequests?.length || 0}
          onClick={() => navigateToQuoteRequests("Pending")}
          isLoading={isLoading}
        />

        <HeaderCard
          icon={CiBadgeDollar}
          title="Revenue"
          value="$23,000"
          isLoading={isLoading}
        />
      </div>
      <div className="p-6 bg-white rounded-lg shadow-md mt-4 border-solid border-2 border-gray-200">
        <div className="mt-4">
          <ActivitySection
            isLoading={isLoading}
            newClientsThisMonth={newClientsThisMonth || 0}
            newClientsThisWeek={newClientsThisWeek || 0}
          />
          <div className="inline-block px-4 py-1 border border-black rounded-full text-sm font-sm text-gray-800 my-4">
            Recent Quote Requests (this week)
          </div>

          <DashboardTable
            quoteRequests={pendingQuoteRequests || []}
            isLoading={isLoading}
          />
        </div>
      </div>
    </TabLayout>
  );
};

export default AdminDashboard;
