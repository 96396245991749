import React from "react";
import { BeatLoader } from "react-spinners";

type BeatLoaderProps = {
  size: number;
};

export const BlueBeatLoader: React.FC<BeatLoaderProps> = ({ size }) => {
  return <BeatLoader size={size} color="#60a5fa" />;
};
