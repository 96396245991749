import React from "react";

export const QuoteRequestSuccess = () => {
  return (
    <div className="flex flex-col gap-8 items-center justify-center min-h-[60vh]">
      <h1 className="text-6xl font-bold">Sent!</h1>
      <h2 className="text-2xl">Thanks for submitting a Quote Request!</h2>
      <p>
        We have sent you an email with your <strong>Quote Number</strong>.
      </p>
      <p>
        You can use your Quote Number to sign into your account to view your
        Quote's status.
      </p>
    </div>
  );
};
