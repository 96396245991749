import React, { useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FileInputProps {
  setSelectedFiles: (files: string[]) => void;
  setValue: any;
  errors: any;
  maxSize?: number;
}

export const FileInputField: React.FC<FileInputProps> = ({
  setSelectedFiles,
  setValue,
  errors,
  maxSize = 3 * 1024 * 1024,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileToBase64 = (file: File) => {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = Array.from(event.target.files || []);
    let totalSize = 0;

    for (const file of files) {
      totalSize += file.size;
    }

    if (totalSize > maxSize) {
      setSelectedFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return toast.error(
        `Files exceed the maximum size limit of ${Math.floor(
          maxSize / 1000000
        )} MB`
      );
    }

    const base64Files = await Promise.all(files.map(fileToBase64));
    setSelectedFiles(base64Files as string[]);
    setValue("selectedFiles", base64Files as string[]);
  };

  return (
    <div className="flex flex-col md:col-span-2">
      <label htmlFor="selectedFiles">Upload Images</label>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        onChange={handleFileChange}
      />
      {errors && <p>{errors.message}</p>}
    </div>
  );
};
