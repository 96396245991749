import { useMutation, useQueryClient } from "@tanstack/react-query";
import authorizedRequest from "../../../utils/authorizedRequest";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { User } from "../../../Types/types";

const API_URL = process.env.REACT_APP_API_URL;

export const useAddUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (user: User) => {
      return await authorizedRequest.post(`${API_URL}/users/adduser`, user);
    },
    onSuccess: () => {
      toast.success(`User added successfully`);
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: (error: AxiosError) => {
      if (error?.response?.status === 400) {
        toast.error("Username already exists");
      } else {
        toast.error("Error adding user. Please try again");
      }
    },
  });
};
