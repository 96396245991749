import React from "react";
import { UseFormRegister } from "react-hook-form";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<any>;
  id: string;
  placeholder?: string;
}
export const Input: React.FC<InputProps> = ({
  register,
  id,
  placeholder,
  ...props
}) => {
  return (
    <input
      id={id}
      {...register(id)}
      className="shadow-sm rounded-md bg-gray-50 border border-gray-300 text-gray-900 w-full p-2.5"
      placeholder={placeholder}
      {...props}
    />
  );
};
