import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Authorizing } from "../pages/Authorizing";
import { useQuery } from "@tanstack/react-query";

interface ProtectedRouteProps {
  children: React.ReactNode;
  permittedRoles: string[];
}

const fetchUserRole = async () => {
  const token = localStorage.getItem("jwt");
  if (!token) {
    throw new Error("No token");
  }
  const API_URL = process.env.REACT_APP_API_URL;
  const response = await axios.get(`${API_URL}/login/user-role`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.status !== 200 || !response.data.valid) {
    throw new Error("Invalid token");
  }
  return response.data.userRole;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  permittedRoles = [],
}) => {
  const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const {
    data: userRole,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["userRole"],
    queryFn: fetchUserRole,
    retry: false,
  });

  if (isLoading) {
    return <Authorizing />;
  }

  if (isError || !userRole) {
    console.log("TOKEN INVALID");
    return <Navigate to="/login" replace />;
  }

  //@ts-ignore
  if (!permittedRoles.includes(userRole)) {
    console.log("USER ROLE: " + userRole);
    console.log("ROLE INVALID");
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
