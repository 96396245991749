import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetchJob } from "../../../hooks/data/jobs/useFetchJob";
import { PageWrapper } from "../../../components/RecordDetail/PageWrapper";
import { MainContentWrapper } from "../../../components/RecordDetail/MainContentWrapper";
import { Header } from "./Header";
import { InfoSection } from "./InfoSection";
import { JobProvider } from "../../../providers/JobProvider";
import { useFetchQuote } from "../../../hooks/data/quotes/useFetchQuote";
import QuotePreview from "../../../components/QuoteBuilder/QuotePreview";
import { AssignedWorker } from "./AssignedWorker";

export const AdminJob = () => {
  const { jobId } = useParams();

  const {
    data: job,
    isLoading: jobIsLoading,
    isError: jobIsError,
    error: jobError,
    refetch: jobRefetch,
  } = useFetchJob(jobId as string);

  const quoteId = job?.quoteId;

  const {
    data: quote,
    isLoading: quoteIsLoading,
    isError: quoteIsError,
  } = useFetchQuote(quoteId as string);

  const isLoading = jobIsLoading || quoteIsLoading;
  const isError = jobIsError || quoteIsError;

  useEffect(() => {
    console.log("JOB ERROR: ", jobError);
  }, [jobError]);

  //TODO: Implement error handling
  //TODO: Implement skeleton loading state

  return (
    <JobProvider
      job={job}
      isLoading={isLoading}
      isError={isError}
      error={jobError}
      refetch={jobRefetch}
    >
      <PageWrapper>
        {!isError && <Header jobId={jobId || ""} />}

        <MainContentWrapper backButtonLabel="Jobs" backButtonLink="/admin/jobs">
          {isError ? (
            <h1 className="font-bold">Job Not Found</h1>
          ) : (
            <div className="flex justify-between gap-4">
              <div>
                <InfoSection />
                <hr className="py-4" />

                <div>
                  <AssignedWorker />
                </div>
              </div>

              <div className="flex flex-col">
                <h1 className="text-grey-darker text-lg font-bold pb-2">
                  Job Quote
                </h1>
                <QuotePreview
                  materials={quote?.materials}
                  labor={quote?.labor}
                  quoteId={quote?.quoteId}
                  quoteInformation={{
                    clientName: quote?.clientName || "",
                    projectName: quote?.projectName || "",
                  }}
                  isLoading={isLoading}
                />
              </div>
            </div>
          )}
        </MainContentWrapper>
      </PageWrapper>
    </JobProvider>
  );
};
